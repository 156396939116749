import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import './index.scss';
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

function AR() {
  const { t } = useTranslation()
  const { languageCode } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })

  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     setAlready(false)
  //   }, 60000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  // function getujjj() {
  //   return (
  //     <video autoplay muted loop width="600">
  //       <source src="https://jovia.my.canva.site/videos/7ee74b9edf5f59d2b7b5653d4c3ea10c.mp4" type="video/mp4">
  //         Your browser does not support the video tag.
  //     </video>
  //   )
  // }

  const [isAvailable, setIsAvailable] = React.useState(false);
  const handleReady = () => {
    setIsAvailable(true);
  };

  return (
    <div className="home-page-section">
      <div className='container'>
        <br /><br />
        <div className='row card-section'>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem', marginBottom: '40px', lineHeight: 1.3 }}><strong>جوفيا
              </strong> - هدية اليوم، ذكرى العمر</div>

              <p className='font-ar-text' style={{ fontSize: '1.5rem', marginBottom: '40px' }}>معظم الهدايا تُنسى بمرور الوقت، لكن التجارب تبقى في الذاكرة. جوڤيا يقدم لك هدايا التجارب الفريدة التي تخلق ذكريات تدوم طويلاً.</p>
              <div className='hp-app-icons-section'>
                <div className='hp-app-icon-card'>
                  <img src='/android-icon.svg' />
                </div>
                <div>&nbsp;&nbsp;</div>
                <div className='hp-app-icon-card'>
                  <img src='/apple-icon.svg' />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 m-m-top' style={{ textAlign: 'center' }}>
            <img src='/hp-box.png' style={{ width: '100%', position: 'relative', top: '-50px' }} />
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem', marginBottom: '40px', lineHeight: 1.3 }}>
                اكتشف كيف تتحول الهدايا إلى لحظات لا تُنسى</div>

              <p className='font-ar-text' style={{ fontSize: '1.5rem' }}>قسائم الهدايا لا تمنح نفس الإثارة التي تشعر بها عند تلقي شيء ملموس، ولكن مع طريقة تقديم جوڤيا الفريدة للقسائم، حولنا الهدايا إلى تجربة ممتعة ومثيرة.</p>
              <p className='font-ar-text' style={{ fontSize: '1.5rem' }}>تخيل الإثارة عندما تُفتح العلبة وتخرج الفراشات، كاشفة عن قسيمة تجربة مصممة بعناية. إنها ليست مجرد هدية، بل لحظة مدهشة لا تُنسى.</p>
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              {isAvailable && <img className='hp-video-frame' src='/mobile-frame.png' />}
              <ReactPlayer
                className='hp-video'
                url={!isMobcreen ?
                  '/jovia-video.mp4' :
                  '/jovia-video.mp4'}
                playing={true}
                controls={false}
                loop={true}
                muted={true}
                playsinline={true}
                onReady={handleReady}
              />
            </div>
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'center' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>هدية لكل مناسبة</div>
              <p className='font-ar-text' style={{ fontSize: '1.5rem' }}>اكتشف مجموعة متنوعة من التجارب المميزة</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-00.jpg' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px', }} />
              <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>دلل أحبائك بأحلى لحظات الراحة</strong></h2>
              <p className='font-ar-text'>دلّلهم بيوم مليان راحة واستجمام، من سبا لجلسات عناية شاملة</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-01.png' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px', }} />
              <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>عيش المغامرة في البر والبحر</strong></h2>
              <p className='font-ar-text'>خلّيهم يعيشوا لحظات مليانة مغامرة، من البر للبحر</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-02.png' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px', }} />
              <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>هدايا مميزة

                تخلّد كل لحظة</strong></h2>
              <p className='font-ar-text'>اختار هدايا مميزة وفريدة تخلّي كل لحظة ما تنسي</p>
            </div>
          </div>
          <div className='col-md-12' style={{ textAlign: 'center', padding: '10px' }}>
            <span style={{ background: '#eee', padding: '10px 20px 10px 20px', borderRadius: '100px', fontSize: '1rem' }}><strong>حمّل التطبيق</strong></span>
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'center' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem', marginBottom: '40px', lineHeight: 1.3 }}>خطوات بسيطة، وهديتك توصل</div>
            </div>
          </div>
          <div className='overflow-sec'>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 style={{ padding: '10px', fontSize: '1rem' }}><strong>تصفح</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-001.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 style={{ padding: '10px', fontSize: '1rem' }}><strong>اختر</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-002.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 style={{ padding: '10px', fontSize: '1rem' }}><strong>خصص</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/hp-step-m.jpg' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 style={{ padding: '10px', fontSize: '1rem' }}><strong>ادفع</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-004.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 style={{ padding: '10px', fontSize: '1rem' }}><strong>تتبع</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-005.jpeg' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>


        <br /><br />
        <div className='row card-section' style={{ paddingBottom: '20px' }}>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div style={{ marginBottom: '40px', lineHeight: 1.3 }}>
                <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>
                  انضم لنا كشريك
                </div>
                <span style={{ fontSize: '1.5rem' }}>خدماتك ستصل لأكبر عدد من العملاء المميزين</span>
              </div>

              <p className='font-ar-text' style={{ fontSize: '1.5rem' }}>جوڤيا، ستحصل على فرصة لإهداء خدماتك المميزة لعملاء يبحثون عن الأفضل. انضم إلينا لتوسيع نطاق عملك والوصول إلى شريحة تقدر الفخامة والجودة. نساعدك على إهداء خدماتك بطريقة تجعل كل لحظة تجربة لا تُنسى.</p>
              <div className='hp-button'>
                <div className='col-md-6' style={{ textAlign: 'center', padding: '10px' }}>
                  <Link className="link-to" to="/partner" style={{ background: '#eee', padding: '10px 20px 10px 20px', borderRadius: '100px', fontSize: '1rem' }}><strong>انضم لنا كشريك</strong></Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'center' }}>
            <img src='/jovia-055.png' style={{ width: '100%' }} />
          </div>
        </div>
      </div>

      <br /><br />
    </div>

  );
}

export default AR;
